$current-color: #3c8bcd;
$event-dot-color: #e22c2c;

.calendar {
    td {
        padding: 0.5rem 0;
    }

    .calendar-content-full-screen {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 0.5rem;
        width: 100%;
        height: 100%;
        overflow: hidden;

        > * {
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .setting-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;

        .close-setting {
            width: 100%;
            text-align: right;
        }

        .setting-content {
            margin: auto;
            min-width: 300px;
            max-width: 100%;
            min-height: 400px;
            max-height: 100%;

            h3 {
                text-align: center;
            }
        }
    }

    .rdt > input[disabled] {
        opacity: 0.5;
    }

    input[type="datetime-local"][disabled] {
        opacity: 0.5;
    }

    hr {
        border: 1px solid transparent;
    }

    .divider {
        height: 1rem;
        width: 100%;
        margin: 1rem;
    }

    .text-align-right {
        text-align: right !important;
    }

    .no-border {
        border: none !important;
    }

    .border-bottom {
        border-bottom: 1px solid $current-color;
    }

    .margin-left-auto {
        margin-left: auto !important;
    }

    .pointer {
        cursor: pointer;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 2rem;
        height: 1rem;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        left: 0;
        bottom: 0;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        transform: translateX(1rem);
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 1rem;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .form-event-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;

        .event-form::-webkit-scrollbar {
            display: none;
        }

        .event-form {
            margin: auto;
            max-height: 100vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 2rem 0;

            .centering-v {
                display: flex;

                > * {
                    margin: auto 0;
                }
            }

            .centering {
                display: flex;

                > * {
                    margin: auto;
                }
            }
        }
    }

    .form-search-container {
        @extend .form-event-container;
        display: grid;
        padding: 1rem;
        grid-template-rows:auto auto 1fr auto;

        .search-form {
            @extend .event-form;
            margin: 0;
            padding-bottom:1rem;
            padding-top:0;
        }

        .search-container-footer {
            text-align: right;
            padding-top:1rem;

            .btn {
                border-radius: 50%;
                border: 1px solid $current-color;
                width: 3rem;
                height: 3rem;
                display: flex;
                margin-left: auto;

                * {
                    margin: auto;
                    display: flex;
                }
            }
        }

        .event-content {
            padding: 0;
            max-height: 100%;
        }
    }

    .btn-add-event {
        position: absolute;
        right: 1rem;
        top: 1rem;
        border-radius: 50%;
        border: 1px solid $current-color;
        margin-right: 1.5rem;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;

        > * {
            margin: auto;
        }
    }

    .event-content::-webkit-scrollbar {
        display: none;
    }

    .event-content {
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 1rem;

        .is-holiday {
            color: $current-color;
        }

        .is-today {
            color: $current-color;
        }

        .event-row {
            padding: 0.5rem 0;
            cursor: pointer;
        }

        .small-text {
            font-size: small;
        }

        .event-time {
            border-right: 1px solid $current-color;
        }

        .event-title {
            display: flex;

            .event-desc {
                margin: auto 0;
            }
        }

        .is-past {
            opacity: 0.5;
        }
    }

    .calendar-header {
        display: flex;
        padding: 0 1rem;

        > div:first-child {
            margin-left: auto;
        }

        .popover-popup-overlay {
            background: transparent !important;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        .popover-popup {
            position: absolute;
            right: 3.5rem;
            padding: 1rem;
            border-radius: 0.5rem;
            z-index: 2;

            &::after {
                content: " ";
                position: absolute;
                top: 40%;
                left: 100%; /* To the right of the tooltip */
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
            }

            > div {
                cursor: pointer;
                z-index: 1;
            }
        }
    }

    .calendar-content {
        display: flex;
        overflow: hidden;

        .year-table-content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            text-align: center;

            div {
                padding: 1rem 0;

                &.selected {
                    color: $current-color;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        .year-table-header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding-bottom: 15px;

            .year-table-year {
                text-align: center;
                font-size: 1.75rem;

                span {
                    border-bottom: 2px solid $current-color;
                }
            }

            .navigate-year-left {
                text-align: right;
                display: flex;

                svg {
                    margin: auto;
                }
            }

            .navigate-year-right {
                text-align: left;
                display: flex;

                svg {
                    margin: auto;
                }
            }
        }

        .navigate-scope {
            display: grid;
            grid-template-columns: 1fr 1fr;
            text-align: center;
            padding-bottom: 1rem;

            .scope-selected {
                color: $current-color;
                text-decoration: underline;
                text-underline-position: under;

                &:hover {
                    color: $current-color;
                    opacity: 0.5;
                }
            }
        }

        .centering {
            margin: auto;
            max-width: 100%;
            width: 100%;
            padding: 0 1rem;
        }

        .calendar-slider {
            overflow-x: visible;
            display: flex;
        }

        .current-year {
            font-size: smaller;
            padding-bottom: 10px;
        }

        .calendar-navigators {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            font-size: larger;

            .left {
                display: flex;

                .nav-left {
                    margin-right: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                    border: 1px solid $current-color;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    display: flex;

                    svg {
                        margin: auto;
                    }
                }
            }

            .right {
                display: flex;

                .nav-right {
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                    border: 1px solid $current-color;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    display: flex;

                    svg {
                        margin: auto;
                    }
                }
            }

            .center {
                text-align: center;

                .back-to-today {
                    margin-top: auto;
                    margin-bottom: auto;
                    display: flex;
                    font-size: 1.5rem;

                    span {
                        margin: auto;
                        border-bottom: 2px solid $current-color;
                    }
                }
            }
        }

        thead, tbody {
            text-align: center;

            .not-current-month {
                opacity: 0.5;
            }

            .date {
                cursor: pointer;
                position: relative;

                .dot-container {
                    overflow: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    .event-dot {
                        font-size: 2rem;
                        color: $current-color;
                        position: absolute;
                        line-height: 0;
                        margin-top: 0.2rem;
                        margin-left: -0.225rem;
                        height: 1px;
                        z-index: -1;
                        top: 50%;
                        left: 50%;
                    }
                }

                .table-div {
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    margin: auto;
                    z-index: 1;

                    .today {
                        color: $current-color;
                    }

                    .date-date {
                        margin: auto;
                    }

                    .holiday {
                        color: $event-dot-color;
                    }
                }

                .current-date {
                    border: 1px solid $current-color;
                    border-radius: 50%;
                }
            }
        }
    }
}
