.general {
    div[class^="fade-"], div[class*=" fade-"] {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .fade-appear,
    .fade-enter {
        opacity: 0;
    }

    .fade-appear-active,
    .fade-enter-active {
        transition: opacity .3s linear;
        opacity: 1;
    }

    .fade-exit {
        transition: opacity .2s linear;
        opacity: 1;
    }

    .fade-exit-active {
        opacity: 0;
    }

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    @media (min-width: 768px) {
        html {
            font-size: 16px;
        }
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }
}
