.select-search-display {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
}

.select-search-search {
    display: grid;
    grid-template-columns: 1fr auto;
    z-index: 1;

    input {
        border: none;
        z-index: 2;

        &:focus {
            outline: none;
        }
    }

    .select-search-input-search{
        background: transparent;
    }

    .select-search-search-options {
        position: relative;

        > div {
            position: absolute;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
            border: 1px solid;
            margin-top: 5px;
        }

        .select-search-option {
            padding: 5px 10px;
            cursor: pointer;
            display: grid;
            grid-template-columns: 1fr auto;
        }

        .select-search-search-option-container {
            overflow-y: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .select-search-search-overlay {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.hide {
    display:none !important;
}

.notification {
    position:absolute;
    top: 1rem;
    left: 0;
    right: 0;
    z-index: 10;
    text-align:center;
}

.confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    --animate-duration: 0.2s;
}

.confirm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
}

.confirm-modal-close {
    position:absolute;
    top:0;
    right:10px;
    padding:12px;
}

.confirm-modal-title {
    font-size: larger;
    font-weight: bolder;
    padding-top: 10px;
}

.confirm-modal-body {
    padding-bottom: 20px;
}

.confirm-modal-footer {
    padding-bottom: 10px;
}

.confirm-modal-footer > .btn-yes {
    margin-left: 10px;
}

.confirm-modal-footer > .btn-no {
    margin-right: 10px;
}

.confirm-modal-content {
    min-width: 300px;
    max-width: 90%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    -webkit-box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.5);
    box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.5);
    border-radius: 10px;
    border-radius: 30px;
}

.close-btn {
    position:absolute;
    top:0;
    right:0px;
    padding:12px;
    line-height:1;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@keyframes ldio-z9zi79joy {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio div {
  position: absolute;
  animation: ldio-z9zi79joy 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio div { box-sizing: content-box; }