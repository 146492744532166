$overlay-opacity: 0.3;

//LIGHT

$background-color-light: #f6f6f6;
$font-color-light: #1d1d1d;
$font-color-light-hover: #777777;
$navbar-bg-overlay-light: #f6f6f6f2; //white alpha 95%
$background-color-light-darker: #eaeaea;
$background-color-light-dark: #d8d8d8;
$selection-color-light: #cdddff;
$overlay-color-light: #f6f6f6;

//DARK
$background-color-dark: #f7ac12;
$font-color-dark: #f6f6f6;
$font-color-dark-hover: #e4e4e4;
$navbar-bg-overlay-dark: #f7ac12f2; //black alpha 95%
$background-color-dark-lighter: #c38810;
$background-color-dark-light: #d39514;
$selection-color-dark: #0069d2;
$overlay-color-dark: #1e1e1e;

//LIGHT ELEMENTS

.light {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: $font-color-light;
    background-color: $background-color-light;

    .color-bg {
        color: $background-color-light;
    }

    .color-sub {
        color: $background-color-dark;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $overlay-color-light;
        opacity: $overlay-opacity;
    }

    ::placeholder {
        color: $font-color-light;
        opacity: 0.5;
    }

    .calendar {
        .setting-container {
            background-color: $background-color-light;
        }

        .popover-popup {
            background-color: $background-color-light-darker;

            &::after {
                border-color: transparent transparent transparent $background-color-light-darker;
            }

            > div {
                &:hover {
                    background-color: $background-color-light-dark;
                }
            }
        }

        .event-row {
            &:hover {
                background: $background-color-light-darker;
            }
        }

        .rdtPicker {
            background-color: $background-color-light;
        }

        button[type="submit"] {
            background-color: transparent;
            border: none;

            > * {
                color: $font-color-light;
            }
        }

        hr {
            color: $background-color-light;
            background-color: $background-color-light;
        }

        .divider {
            background-color: $background-color-light-darker;
        }

        .form-control {
            background: none;
            border: none;
            border-radius: 0;
            padding-left: 0;
            color: $font-color-light;
            border-color: transparent;

            &:focus {
                outline: none;
                box-shadow: none;
                border-color: transparent;
                border: none;
            }

            option {
                background-color: $background-color-light-darker;
                color: $font-color-light;
            }
        }

        .form-event-container {
            background-color: $background-color-light;
        }

        .form-search-container {
            @extend .form-event-container;
        }

        .btn {
            color: $font-color-light;

            &:hover {
                color: $font-color-light-hover;
            }
        }

        .btn-outline-secondary {
            color: $font-color-light;

            &:hover {
                color: $font-color-light;
                background-color: $background-color-light-darker;
            }
        }
    }

    .select-search-input-search {
        color: $font-color-light;
    }

    .select-search-option {
        background-color: $background-color-light-darker;
        color: $font-color-light;

        &:hover {
            background-color: $background-color-light-dark;
            color: $font-color-light-hover;
        }

        &.selected {
            background-color: $selection-color-light;
        }
    }

    .admin {
        .table-responsive {
            th {
                background-color: $background-color-light-darker;
            }

            .table-content {
                tr.selected {
                    background-color: $selection-color-light;
                }
            }
        }
    }

    .option-modal-overlay {
        background-color: $navbar-bg-overlay-light;
    }

    .option-modal-content {
        background-color: $background-color-light-darker;
    }

    .table-content {
        tr:hover, .id-selected {
            background-color: $background-color-light-dark;
        }
    }

    .card {
        background-color: $background-color-light-darker;
        color: $font-color-light;
    }

    a {
        color: #0366d6;
    }

    code {
        color: #E01A76;
    }

    .confirm-modal-close, .option-modal-close {
        color: $font-color-light;

        &:hover {
            color: $font-color-light;
            opacity: 0.75;
        }
    }

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .topbar-bars {
        color: $font-color-light;
    }

    .dropdown-menu {
        background-color: $background-color-light-dark;

        > a, button {
            color: $font-color-light;

            &:hover, &:focus {
                background-color: $background-color-light-darker;
                text-decoration: none;
            }
        }
    }

    .sidebar {
        background-color: $background-color-light-darker;
    }

    .nav-link {
        color: $font-color-light !important;

        &:hover, &:focus {
            color: $font-color-light-hover !important;
        }
    }

    .navbar-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $background-color-light;
        opacity: 0.97;
        z-index: -1;
    }

    @media (max-width: 574px) {
        .navbar .navbar-collapse {
            background: $navbar-bg-overlay-light;
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: opacity 0.35s linear;
            display: flex;
            flex-direction: column;
            align-content: center;
            text-align: center !important;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    .confirm-modal-overlay {
        background-color: $background-color-light;
    }

    .confirm-modal-content {
        background-color: $background-color-light;
    }

    .loading {
        background-color: transparent;
    }

    .table {
        color: $font-color-light;
    }

    .ldio div {
        box-shadow: 0 2px 0 0 $font-color-light;
    }
}

//DARK ELEMENTS

.dark {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: $font-color-dark;
    background-color: $background-color-dark;

    .color-bg {
        color: $background-color-dark;
    }

    .color-sub {
        color: $background-color-light;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $overlay-color-dark;
        opacity: $overlay-opacity;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    ::placeholder {
        color: $font-color-dark;
        opacity: 0.5;
    }

    .calendar {
        .setting-container {
            background-color: $background-color-dark;
        }

        .popover-popup {
            background-color: $background-color-dark-lighter;

            &::after {
                border-color: transparent transparent transparent $background-color-dark-lighter;
            }

            > div {
                &:hover {
                    background-color: $background-color-dark-light;
                }
            }
        }

        .event-row {
            &:hover {
                background: $background-color-dark-lighter;
            }
        }

        .rdtPicker {
            background-color: $background-color-dark;

            .rdtPrev, .rdtSwitch, .rdtNext, td, .rdtBtn {
                &:hover {
                    background-color: $background-color-dark-lighter;
                }
            }
        }

        button[type="submit"] {
            background-color: transparent;
            border: none;

            > * {
                color: $font-color-dark;
            }
        }

        hr {
            color: $background-color-dark;
            background-color: $background-color-dark;
        }

        .divider {
            background-color: $background-color-dark-lighter;
        }

        .form-control {
            background: none;
            border: none;
            border-radius: 0;
            padding-left: 0;
            color: $font-color-dark;
            border-color: transparent;

            &:focus {
                outline: none;
                box-shadow: none;
                border-color: transparent;
                border: none;
            }

            option {
                background-color: $background-color-dark-lighter;
                color: $font-color-dark;
            }
        }

        .form-event-container {
            background-color: $background-color-dark;
        }

        .form-search-container {
            @extend .form-event-container;
        }

        .btn {
            color: $font-color-dark;

            &:hover {
                color: $font-color-dark-hover;
            }
        }

        .btn-outline-secondary {
            color: $font-color-dark;

            &:hover {
                color: $font-color-dark;
                background-color: $background-color-dark-lighter;
            }
        }
    }

    .select-search-input-search {
        color: $font-color-dark;
    }

    .select-search-option {
        background-color: $background-color-dark-lighter;
        color: $font-color-dark;

        &:hover {
            background-color: $background-color-dark-light;
            color: $font-color-dark-hover;
        }

        &.selected {
            background-color: $selection-color-dark;
        }
    }

    .admin {
        .table-responsive {
            th {
                background-color: $background-color-dark-lighter;
            }

            .table-content {
                tr.selected {
                    background-color: $selection-color-dark;
                }
            }
        }
    }

    .option-modal-overlay {
        background-color: $navbar-bg-overlay-dark;
    }

    .option-modal-content {
        background-color: $background-color-dark-lighter;
    }

    .table-content {
        tr:hover, .id-selected {
            background-color: $background-color-dark-light;
        }
    }

    .card {
        background-color: $background-color-dark-lighter;
        color: $font-color-dark;
    }

    a {
        color: #0366d6;
    }

    code {
        color: #E01A76;
    }

    .confirm-modal-close, .option-modal-close {
        color: $font-color-dark;

        &:hover {
            color: $font-color-dark;
            opacity: 0.75;
        }
    }

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .topbar-bars {
        color: $font-color-dark;
    }

    .dropdown-menu {
        background-color: $background-color-dark-light;

        > a, button {
            color: $font-color-dark;

            &:hover, &:focus {
                background-color: $background-color-dark-lighter;
                text-decoration: none;
            }
        }
    }

    .sidebar {
        background-color: $background-color-dark-lighter;
    }

    .nav-link {
        color: $font-color-dark !important;

        &:hover, &:focus {
            color: $font-color-dark-hover !important;
        }
    }

    .navbar-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $background-color-dark;
        opacity: 0.97;
        z-index: -1;
    }

    @media (max-width: 574px) {
        .navbar .navbar-collapse {
            background: $navbar-bg-overlay-dark;
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: opacity 0.35s linear;
            display: flex;
            flex-direction: column;
            align-content: center;
            text-align: center !important;
            overflow-y: scroll;
            overflow-x: hidden
        }
    }

    .confirm-modal-overlay {
        background-color: $background-color-dark;
    }

    .confirm-modal-content {
        background-color: $background-color-dark;
    }

    .loading {
        background-color: transparent;
    }

    .table {
        color: $font-color-dark;
    }

    .ldio div {
        box-shadow: 0 2px 0 0 $font-color-dark;
    }
}

//GENERAL

.full-page {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.relative {
    position: relative;
}

.theme-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
}

.navbar {
    z-index: 1;
}

.navbar .navbar-toggler {
    z-index: 1;
}

@media (max-width: 574px) {
    .navbar .navbar-collapse.collapse {
        display: flex;
        opacity: 0;
        pointer-events: none
    }

    .navbar .navbar-collapse.collapsing[style*="height"] {
        opacity: 1;
        height: 100vh !important
    }

    .navbar .navbar-collapse.collapsing {
        opacity: 0;
        height: 100vh
    }

    .navbar .navbar-collapse.collapse.show {
        opacity: 1;
        pointer-events: auto
    }

    .navbar .navbar-collapse .navbar-nav {
        margin-right: initial !important;
        margin-top: auto;
        margin-bottom: auto
    }

    .nav-item {
        padding-bottom: 10px;
    }

    .navbar-nav {
        margin-left: initial !important;
    }
}

.middle-vertical {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    > div {
        padding: 1rem;
    }
}

.form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    form {
        min-width: 300px;
        max-width: 100%;
    }

    .login-form-footer {
        padding-top: 50px;
    }
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

#company-video {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    object-fit:cover;
    z-index:-1;
}

.absolute {
    position:absolute !important;
}

.navbar-collapse::-webkit-scrollbar {
    width: 0 !important;
}

.padding-0 {
    padding:0 !important;
}

.shift-by-nav {
    padding-top:5rem;
}

.flex {
    display:flex;
}

.flex-center {
    margin:auto;
}

.w-100 {
    width:100%;
}

.h-100-vh {
    height:100vh;
}

.behind {
    z-index:-1;
}

.content {
    .content-subtitle {
        padding-bottom: 1rem;
    }
}

.text-light {
    color: $font-color-dark;
}

.text-dark {
    color: $font-color-light;
}

.background-light {
    background-color: $background-color-light;
}

.background-dark {
    background-color: $background-color-dark;
}

.bg-none {
    background-color:transparent !important;
}

.text-center {
    text-align:center;
}

.thumbnail {
    width:100%;
    object-fit: cover;
}

.p-top-1 {
    padding-top: 1rem;
}

.p-top-2 {
    padding-top: 2rem;
}

.p-top-3 {
    padding-top: 3rem;
}

.p-top-4 {
    padding-top: 4rem;
}

.p-top-5 {
    padding-top: 5rem;
}

.p-bot-0 {
    padding-bottom: 0;
}

.p-bot-1 {
    padding-bottom: 1rem;
}

.p-bot-2 {
    padding-bottom: 2rem;
}

.p-bot-3 {
    padding-bottom: 3rem;
}

.p-bot-4 {
    padding-bottom: 4rem;
}

.p-bot-5 {
    padding-bottom: 5rem;
}

.margin-bottom-0 {
    margin:0 !important;
}

.fixed-nav {
    position:fixed;
    top:0;
    left:0;
    right:0;
}

.social-icon {
    font-size: 2rem;
    padding-right: 1rem;

    > a {
        color: $font-color-dark;

        &:hover {
            color: $font-color-dark-hover;
        }
    }
}

a.text-light {
    color: $font-color-light;

    &:hover {
        color: $font-color-light-hover;
    }
}

#gmap_canvas {
    width: 1150px;
    max-width:100%;
    height: 500px;
}

.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 1150px;
    max-width:100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 1150px;
    max-width: 100%;
}