.admin {
    .table-card {
        max-height: 80vh;
        display: grid;
        grid-template-rows: auto auto 1fr auto;

        .card-header {
            text-align: center;
            font-size: larger;
            font-weight: bold;
        }

        .selection-btn {
            margin-right: 10px;
        }
    }

    .option-modal-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;

        .option-modal-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .option-modal-content {
            min-width: 350px;
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: grid;
            grid-template-rows: auto 1fr auto;
            border-radius: 20px;
            padding: 15px;
            max-height: 95vh;
            overflow-y: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            .option-modal-header {
                text-align: center;

                .option-modal-close {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }

            .option-modal-body {
                .option-modal-option {
                    cursor: pointer;
                    padding: 10px;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            .option-modal-footer {
                text-align: right;

                > button {
                    margin-left: 10px;
                }
            }
        }
    }

    .th {
        padding: 0;
        font-weight: bold;
    }

    .transition-group {
        position: relative;
    }

    .admin-content {
        padding: 15px;
    }

    div[class^="fade-"], div[class*=" fade-"] {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .fade-appear,
    .fade-enter {
        opacity: 0;
    }

    .fade-appear-active,
    .fade-enter-active {
        transition: opacity .3s linear;
        opacity: 1;
    }

    .fade-exit {
        transition: opacity .2s linear;
        opacity: 1;
    }

    .fade-exit-active {
        opacity: 0;
    }

    .nav-link-icon {
        width: 20px !important;
        margin-right: 3px !important;
    }

    .navbar-nav .nav-item.dropdown .dropdown-toggle::after {
        content: '';
    }

    .dropdown-toggle {
        > span, svg {
            align-self: center;
        }
    }

    .caret {
        margin-left: auto !important;
    }

    .sidebar {
        min-height: calc(100vh - 60px);
    }

    .sidebar-dropdown {
        display: flex !important;

        &:focus {
            box-shadow: none;
        }
    }

    @media (max-width: 574px) {
        .sidebar-dropdown {
            display: block !important;
        }

        .sidebar {
            > .dropdown {
                > .dropdown-menu {
                    top: 20px;
                }
            }
        }
    }

    .dropdown-menu {
        padding: 5px;
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }

    button.navbar-brand {
        background: transparent;
        border: none;
        outline: none;
    }

    .topbar-nav {
        margin-left: auto !important;

        > li {
            padding-bottom: 0;
        }
    }

    .topbar-bars {
        padding-top: 8px;
    }

    #wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    #wrapper #content-wrapper {
        overflow-x: hidden;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 80px
    }

    body.fixed-nav #content-wrapper {
        margin-top: 56px;
        padding-left: 90px
    }

    body.fixed-nav.sidebar-toggled #content-wrapper {
        padding-left: 0
    }

    @media (min-width:768px) {
        body.fixed-nav #content-wrapper {
            padding-left: 225px
        }

        body.fixed-nav.sidebar-toggled #content-wrapper {
            padding-left: 90px
        }
    }

    .scroll-to-top {
        position: fixed;
        right: 15px;
        bottom: 15px;
        display: none;
        width: 50px;
        height: 50px;
        text-align: center;
        color: #fff;
        background: rgba(52,58,64,.5);
        line-height: 46px
    }

    .scroll-to-top:focus, .scroll-to-top:hover {
        color: #fff
    }

    .scroll-to-top:hover {
        background: #343a40
    }

    .scroll-to-top i {
        font-weight: 800
    }

    .smaller {
        font-size: .7rem
    }

    .o-hidden {
        overflow: hidden !important
    }

    .z-0 {
        z-index: 0
    }

    .z-1 {
        z-index: 1
    }

    .navbar-nav .form-inline .input-group {
        width: 100%
    }

    .navbar-nav .nav-item.dropdown .dropdown-toggle::after {
        width: 1rem;
        text-align: center;
        float: right;
        vertical-align: 0;
        border: 0;
        font-weight: 900;
        content: none;
    }

    .navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
        content: none;
    }

    .navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
        display: none;
    }

    .navbar-nav .nav-item .nav-link:focus {
        outline: 0;
    }

    .navbar-nav .nav-item .nav-link .badge {
        position: absolute;
        margin-left: .75rem;
        top: .3rem;
        font-weight: 400;
        font-size: .5rem
    }

    @media (min-width:768px) {
        .navbar-nav .form-inline .input-group {
            width: auto
        }
    }

    .sidebar {
        width: 90px !important;
        background-color: #212529;
        min-height: calc(100vh - 60px)
    }

    .sidebar .nav-item:last-child {
        margin-bottom: 1rem
    }

    .sidebar .nav-item .nav-link {
        text-align: center;
        padding: .75rem 1rem;
        width: 90px
    }

    .sidebar .nav-item .nav-link span {
        font-size: .65rem;
        display: block
    }

    .sidebar .nav-item .dropdown-menu {
        position: absolute !important;
        -webkit-transform: none !important;
        transform: none !important;
        left: calc(90px + .5rem) !important;
        margin: 0
    }

    .sidebar .nav-item .dropdown-menu.dropup {
        bottom: 0;
        top: auto !important
    }

    .sidebar .nav-item.dropdown .dropdown-toggle::after {
        display: none
    }

    .sidebar.toggled {
        width: 0 !important;
        overflow: hidden
    }

    @media (min-width:768px) {
        .sidebar {
            width: 225px !important
        }

        .sidebar .nav-item .nav-link {
            display: block;
            width: 100%;
            text-align: left;
            padding: 1rem;
            width: 225px
        }

        .sidebar .nav-item .nav-link span {
            font-size: 1rem;
            display: inline
        }

        .sidebar .nav-item .dropdown-menu {
            position: static !important;
            margin: 0 1rem;
            top: 0
        }

        .sidebar .nav-item.dropdown .dropdown-toggle::after {
            display: block
        }

        .sidebar.toggled {
            overflow: visible;
            width: 90px !important
        }

        .sidebar.toggled .nav-item:last-child {
            margin-bottom: 1rem
        }

        .sidebar.toggled .nav-item .nav-link {
            text-align: center;
            padding: .75rem 1rem;
            width: 90px
        }

        .sidebar.toggled .nav-item .nav-link span {
            font-size: .65rem;
            display: block
        }

        .sidebar.toggled .nav-item .dropdown-menu {
            position: absolute !important;
            -webkit-transform: none !important;
            transform: none !important;
            left: calc(90px + .5rem) !important;
            margin: 0
        }

        .sidebar.toggled .nav-item .dropdown-menu.dropup {
            bottom: 0;
            top: auto !important
        }

        .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
            display: none
        }
    }

    .sidebar.fixed-top {
        top: 56px;
        height: calc(100vh - 56px);
        overflow-y: auto
    }

    .card-body-icon {
        position: absolute;
        z-index: 0;
        top: -1.25rem;
        right: -1rem;
        opacity: .4;
        font-size: 5rem;
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    @media (min-width:576px) {
        .card-columns {
            -webkit-column-count: 1;
            column-count: 1
        }
    }

    @media (min-width:768px) {
        .card-columns {
            -webkit-column-count: 2;
            column-count: 2
        }
    }

    @media (min-width:1200px) {
        .card-columns {
            -webkit-column-count: 2;
            column-count: 2
        }
    }

    :root {
        --input-padding-x: 0.75rem;
        --input-padding-y: 0.75rem
    }

    .card-login {
        max-width: 25rem
    }

    .card-register {
        max-width: 40rem
    }

    .form-label-group {
        position: relative
    }

    .form-label-group > input, .form-label-group > label {
        padding: var(--input-padding-y) var(--input-padding-x);
        height: auto
    }

    .form-label-group > label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        line-height: 1.5;
        color: #495057;
        border: 1px solid transparent;
        border-radius: .25rem;
        -webkit-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out
    }

    .form-label-group input::-webkit-input-placeholder {
        color: transparent
    }

    .form-label-group input:-ms-input-placeholder {
        color: transparent
    }

    .form-label-group input::-ms-input-placeholder {
        color: transparent
    }

    .form-label-group input::placeholder {
        color: transparent
    }

    .form-label-group input:not(:placeholder-shown) {
        padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
        padding-bottom: calc(var(--input-padding-y)/ 3)
    }

    .form-label-group input:not(:placeholder-shown) ~ label {
        padding-top: calc(var(--input-padding-y)/ 3);
        padding-bottom: calc(var(--input-padding-y)/ 3);
        font-size: 12px;
        color: #777
    }

    footer.sticky-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc(100% - 90px);
        height: 80px;
        background-color: #e9ecef
    }

    footer.sticky-footer .copyright {
        line-height: 1;
        font-size: .8rem
    }

    @media (min-width:768px) {
        footer.sticky-footer {
            width: calc(100% - 225px)
        }
    }

    body.sidebar-toggled footer.sticky-footer {
        width: 100%
    }

    @media (min-width:768px) {
        body.sidebar-toggled footer.sticky-footer {
            width: calc(100% - 90px)
        }
    }

    .btn {
        white-space: normal !important;
    }
}
